import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import { IoLogOutOutline } from "react-icons/io5";
import UserProfileBadge from "./UserProfileBadge";
import * as Context from "../Context";
import * as Constants from "../Constants";
import * as Components from "../Components";
import AppMenu from "./AppMenu";
import logo from "../Assets/rem_logo_trans.png";

const AppHeader = () => {
    const [showProfile, setShowProfile] = useState(true);
    const { user, logout } = useContext(Context.UserContext);
    const current_url = useLocation()

    const LogoutButton = (props) => {
        return (
            <div onClick={logout} {...props}>
                <IoLogOutOutline size={30} className="text-red-600" />
            </div>
        )
    }

    useEffect(() => {
        setShowProfile(false)
    }, [current_url])

    return (
        <nav className="bg-primary border-gray-200 px-2 py-2 md:px-8 md:pb-4 w-full z-50 shadow-sm h-full">
            <div className="container flex flex-wrap justify-between items-center mx-auto h-10">
                <div className="flex gap-4">
                    <a href="/" className="flex items-center">
                        <img src={logo} alt="REM-logo" className="w-16 mr-4 hover:cursor-pointer" />
                        <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
                            {Constants.BRAND_NAME}
                        </span>
                    </a>
                    <div className="hidden md:flex gap-4 items-center justify-center ml-4">
                        <AppMenu />
                    </div>
                </div>

                <div className="flex gap-4">
                    {/* Large screen */}
                    <div className="hidden md:flex md:gap-4 items-center">
                        <UserProfileBadge name={user.full_name} email={user.email} />
                        <LogoutButton className="cursor-pointer" />
                    </div>

                    {/* Small screen */}
                    <div className="block md:hidden">
                        <div onClick={() => setShowProfile(true)}>
                            <BiMenu size={30} className="text-gray-600" />
                        </div>
                        {showProfile && (
                            <Components.SlideOver title="Menu" onClose={() => setShowProfile(false)}>
                                <div className="flex flex-col justify-between h-[88vh] divide-y">
                                    <div className="flex flex-col divide-y divide-gray-100 overflow-y-auto">
                                        <AppMenu className="py-2 px-4" />
                                    </div>
                                    <div className="flex justify-between p-3">
                                        <UserProfileBadge name={user.full_name} email={user.email} />
                                        <LogoutButton className="mr-4 cursor-pointer" />
                                    </div>
                                </div>
                            </Components.SlideOver>
                        )}
                    </div>
                </div>
            </div>
        </nav >
    );
};

export default AppHeader;
