/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import * as Context from "../../Context";
import * as GeneratorService from "../../Services/GeneratorService";
import * as RequestColumns from "../../Data/Request.json";
import * as ROUTES from "../../routes";
import { RequestTable } from "../../Components/Generator";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../Components";
import { MESSAGES, REQUEST_DETAILS, RESET_PAGINATION } from "../../Constants";
const Request = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(Context.UserContext);
  const [pagination, setPagination] = useState(RESET_PAGINATION);
  const tableColumns = [
    "purchase_start_date",
    "purchase_end_date",
    "power_requirement_kwh",
    "min_rate_rs_per_kwh",
    "purchase_type",
    "status",
    "actions",
  ];
  const navigate = useNavigate();
  const getRequest = () => {
    if (pagination.offset > pagination.count) return;
    GeneratorService.getGeneratorRequest(user.entity_id, pagination)
      .then((response) => {
        if (response.status === 200) {
          setRequests((prevData) => {
            let updatedData = [];
            updatedData = new Set([
              ...prevData.map((data) => JSON.stringify(data)),
              ...response.data.results.map((data) => JSON.stringify(data)),
            ]);
            return Array.from(updatedData).map((data) => JSON.parse(data));
          });
        } else {
          toast.error(MESSAGES.REQUEST_ERROR);
        }
        setPagination({
          ...pagination,
          count: response.data.count,
          offset: pagination.offset + pagination.limit,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const renderFields = (key, rowData) => {
    if (key === "actions") {
      return (
        <button className="text-primary hover:text-secondary">
          view Details
        </button>
      );
    }
    return rowData[key];
  };

  const rowClick = (rowData) => {
    navigate(ROUTES.GENERATOR_REQUEST_DETAILS.replace(":id", rowData.id));
  };

  useEffect(() => {
    setLoading(true);
    getRequest();
  }, [user]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex justify-between mb-4">
            <div className="text-xl text-primary font-semibold mb-4">
              {REQUEST_DETAILS}
            </div>
          </div>
          <div>
            <InfiniteScroll
              dataLength={requests.length}
              next={getRequest}
              hasMore={pagination.offset <= pagination.count}
              scrollableTarget="scrollableDiv"
            >
              <div
                className="h-table_desk overflow-x-auto overflow-y-auto md:border md:rounded-md"
                id="scrollableDiv"
              >
                <RequestTable
                  tableColumns={tableColumns}
                  tableRows={requests}
                  columnLabels={RequestColumns}
                  className={""}
                  renderField={renderFields}
                  fieldClick={(key, row) => rowClick(row)}
                />
              </div>
            </InfiniteScroll>
          </div>
        </>
      )}
    </>
  );
};

export default Request;
