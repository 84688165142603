import client from "./axiosConfig";

export const getGenerator = (generatorId) => {
  const config = {
    url: `/generator/${generatorId}`,
    method: "GET"
  };
  const response = client(config);
  return response;
};

export const updateGeneratorProfile = (id, data) => {
  const config = {
    url: `/generator/${id}/`,
    method: "PATCH",
    data
  };
  const response = client(config);
  return response;
};

export const getGeneratorRequest = (generatorId,params) => {
  const config = {
    url: `/generator/${generatorId}/request/`,
    method: "GET",
    params
  };
  const response = client(config);
  return response;
};

export const getGeneratorRequestDetails = (generatorId, requestId) => {
  const config = {
    url: `/generator/${generatorId}/request/${requestId}`,
    method: "GET"
  };
  const response = client(config);
  return response;
};

export const getQuoteDetails = (generatorId, requestId) => {
  const config = {
    url: `/generator/${generatorId}/request/${requestId}/quote/`,
    method: "GET"
  };
  const response = client(config);
  return response;
};

export const postQuote = (generatorId, requestId ,data) => {
  const config = {
    url: `/generator/${generatorId}/request/${requestId}/quote/`,
    method: "POST",
    data
  };
  const response = client(config);
  return response;
};