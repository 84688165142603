import React from "react";
import { Link } from "react-router-dom"
import { LOGIN } from "../routes"
import * as Components from "../Components";

const SignUp = () => {
    return (
        <div className="flex flex-col md:flex-row gap-8 text-center md:justify-between">
            <Components.ComingSoon />
            <hr className="md:hidden shadow-md"></hr>
            <div className="flex justify-center items-center text-left">
                <div className="m-auto px-6 py-4 space-y-2 sm:mx-auto sm:w-full sm:max-w-md bg-white border rounded-lg shadow-md">
                    <div className="flex flex-col gap-4 items-center rounded-lg">
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-secondary md:text-2xl dark:text-black">
                            Create new account
                        </h1>
                        <div className="w-full">
                            <Components.SignUpForm />
                        </div>
                    </div>
                    <p className="text-center text-sm text-secondary">
                        Already a member?{' '}
                        <Link to={LOGIN} className="font-semibold leading-6 text-primary hover:text-secondary">
                            Login Now!
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
