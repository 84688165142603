import { ROLES } from "./Constants"

export const HOME = "/"
export const LOGIN = "/login"
export const SIGNUP = "/signup"
export const PROFILE = "/profile"
export const REQUEST = "/request"
export const REQUESTID = "/:id"

// User routes
export const CONSUMER = "/consumer"
export const CONSUMER_PROFILE = CONSUMER + PROFILE
export const CONSUMER_REQUEST = CONSUMER + REQUEST
export const CONSUMER_REQUEST_DETAILS = CONSUMER + REQUEST + REQUESTID;


// Agent Routes
export const AGENT = "/agent"
export const AGENT_PROFILE = AGENT + PROFILE
export const AGENT_REQUEST = AGENT + REQUEST
export const AGENT_REQUEST_DETAILS = AGENT + REQUEST + REQUESTID;

// Generator Routes
export const GENERATOR = "/generator"
export const GENERATOR_PROFILE = GENERATOR + PROFILE
export const GENERATOR_REQUEST = GENERATOR + REQUEST
export const GENERATOR_REQUEST_DETAILS = GENERATOR + REQUEST + REQUESTID;

export const MENU_OPTIONS = {
    // TODO: Change roles
    [ROLES.CONSUMER]: {
        "Home": HOME,
        "Profile": CONSUMER_PROFILE,
        "Request": CONSUMER_REQUEST,
    },
    [ROLES.AGENT]: {
        "Home": HOME,
        "Profile": AGENT_PROFILE,
        "Request": AGENT_REQUEST,
    },
    [ROLES.GENERATOR]: {
        "Home": HOME,
        "Profile": GENERATOR_PROFILE,
        "Request": GENERATOR_REQUEST,
    }
}