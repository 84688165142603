import React from "react";
import { Link } from "react-router-dom";
import * as Constants from "../Constants";
import * as routes from "../routes";
import logo from "../Assets/rem_logo_trans.png";

const Header = () => {
  return (
    <nav className=" bg-primary border-gray-200 px-2 sm:px-4 ">
      <div className="container flex flex-wrap justify-between items-center mx-auto h-full">
        <a href="/" className="flex items-center">
          <img
            src={logo}
            alt="REM-logo"
            className="w-16 mr-4 hover:cursor-pointer"
          />
          <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
            {Constants.BRAND_NAME}
          </span>
        </a>
        <div className="hidden w-full md:block md:w-auto">
          <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:font-medium">
            <li>
              <Link
                to={routes.LOGIN}
                className="block py-2 pr-4 pl-3 hover:text-secondary rounded md:bg-transparent md:p-0"
              >
                Login
              </Link>
            </li>
            <li>
              <Link
                to={routes.SIGNUP}
                className="block py-2 pr-4 pl-3 hover:text-secondary rounded md:bg-transparent md:p-0"
                aria-current="page"
              >
                Create Account
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
