import client from "./axiosConfig";

export const getAgent = (AgentId) => {
  const config = {
    url: `/agent/${AgentId}`,
    method: "GET",
  };
  const response = client(config);
  return response;
};

export const updateAgentProfile = (AgentId, data) => {
  const config = {
    url: `/agent/${AgentId}/`,
    method: "PATCH",
    data,
  };
  const response = client(config);
  return response;
};

export const getAgentRequests = (AgentId, params) => {
  const config = {
    url: `/agent/${AgentId}/requests/`,
    method: "GET",
    params,
  };
  const response = client(config);
  return response;
};
export const getAgentRequestDetails = (AgentId, requestId) => {
  const config = {
    url: `/agent/${AgentId}/requests/${requestId}/`,
    method: "GET",
  };
  const response = client(config);
  return response;
};
export const getQuoteList = (AgentId, requestId) => {
  const config = {
    url: `/agent/${AgentId}/requests/${requestId}/quote/`,
    method: "get",
  };
  const response = client(config);
  return response;
};
export const postAgentRequest = (AgentId, data) => {
  const config = {
    url: `/agent/${AgentId}/requests/`,
    method: "POST",
    data,
  };
  const response = client(config);
  return response;
};

export const patchAgentRequest = (AgentId, data, requestId) => {
  const config = {
    url: `/agent/${AgentId}/requests/${requestId}/`,
    method: "PATCH",
    data,
  };
  const response = client(config);
  return response;
};
