import React from "react";
import logo from "../Assets/rem_logo_trans.png";


const ComingSoon = () => {

    return (
        <div className="flex flex-col w-full md:w-1/2 gap-4 justify-center items-center">
            <img src={logo} alt="REM-logo" className="w-64 mr-4 hover:cursor-pointer" />
            <span className="text-xl md:text-2xl text-secondary">Online Platform for renewable energy power purchase.</span>
            <span className="text-xl md:text-3xl text-primary">Coming Soon!!</span>
        </div>
    )
}

export default ComingSoon;