import React, { useState } from "react";
import { ASC, DESC } from "../Constants";
// tableColumns: Actual Column Names (required) Array of String
// tableRows: Table Rows object (required) Array of objects
// columnLabels: Column Labels to be shown on UI (key value JSON)
// renderField: Formatting on row data function
// sortingColumn :applies sorting on this Column 

const CommonTable = ({
  tableColumns,
  tableRows,
  columnLabels = null,
  rowClick = null,
  headerClassName = null,
  renderField = null,
  className = null,
  fieldClick = null,
  sortingColumn = null,
  ...rest
}) => {
  const defaultClasses = "w-full text-left text-gray-500 border";
  const classes = `${defaultClasses} ${className}`;
  const [sortOrder, setSortOrder] = useState(ASC);
  const toggleSortOrder = () => {
    setSortOrder(sortOrder === ASC ? DESC : ASC);
  };
  const sortRequests = (
    tableRows = [],
    sortBy = sortingColumn,
    sortOrder = ASC
  ) => {
    return tableRows.sort((a, b) => {
      const dateA = new Date(a[sortBy]);
      const dateB = new Date(b[sortBy]);

      if (sortOrder === ASC) {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });
  };
  const sortedRows = sortRequests(tableRows, sortingColumn, sortOrder);
  return (
    <>
      {/* Table view for larger screens */}
      <div className="hidden md:block overflow-x-auto">
        <table className={classes} {...rest}>
          <thead
            className={`sticky top-0 text-sm text-secondary bg-gray-50 ${headerClassName}`}
          >
            <tr className="shadow-md">
              {tableColumns.map((key) => (
                <th scope="col" className="px-2 py-3" key={key}>
                  {columnLabels ? columnLabels[key] : key}
                  {key === sortingColumn && (
                    <button onClick={toggleSortOrder} className="ml-2 text-sm">
                      {sortOrder === ASC ? "↑" : "↓"}
                    </button>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedRows?.map((row, index) => (
              <tr
                key={index}
                className={`border-b ${rowClick ? "cursor-pointer" : ""}`}
                onClick={rowClick ? () => rowClick(row) : null}
              >
                {tableColumns.map((key) => (
                  <td
                    className={`px-2 py-2 whitespace-nowrap ${
                      fieldClick ? "cursor-pointer" : ""
                    }`}
                    key={key}
                    onClick={fieldClick ? () => fieldClick(key, row) : null}
                  >
                    {renderField ? renderField(key, row) : row[key]}
                  </td>
                ))}
              </tr>
            ))}
            {!sortedRows || sortedRows?.length === 0 ? (
              <tr>
                <td
                  className="text-lg text-center h-[62vh]"
                  colSpan={tableColumns.length}
                >
                  No data available!
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>

      {/* Mobile view */}
      <div className="block md:hidden space-y-4 p-4">
        {sortedRows?.map((row, index) => (
          <div
            key={index}
            className={`border rounded-md shadow-md p-4 ${
              rowClick ? "cursor-pointer" : ""
            }`}
            onClick={rowClick ? () => rowClick(row) : null}
          >
            {tableColumns.map((key) => (
              <div key={key} className="flex justify-between py-2 items-center">
                <span className="font-medium text-gray-700">
                  {columnLabels ? columnLabels[key] : key}:
                </span>
                <span
                  className={`pl-2 text-gray-900 ${
                    fieldClick ? "cursor-pointer" : ""
                  }`}
                  onClick={fieldClick ? () => fieldClick(key, row) : null}
                >
                  {renderField ? renderField(key, row) : row[key]}
                </span>
              </div>
            ))}
          </div>
        ))}
        {!sortedRows || sortedRows?.length === 0 ? (
          <div className="text-lg text-center h-full">No data available!</div>
        ) : null}
      </div>
    </>
  );
};

export default CommonTable;
