/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import toast from "react-hot-toast";
import * as Component from "../../Components";
import * as Context from "../../Context";
import * as ConsumerService from "../../Services/ConsumerService";
import * as Common from "../../Components/Common";
import * as Constants from "../../Constants";
import * as RequestColumns from "../../Data/Request.json";
import * as ROUTES from "../../routes";

import {
  TECHNOLOGY_OPTIONS,
  TENURE_OPTIONS,
  PURCHASE_TYPE_OPTIONS,
  DRAFT,
  EDIT,
  MESSAGES,
  BASIC_FORM_DATA,
  BASIC_COLUMN_LABELS,
  REQUEST_LIST,
  RESET_PAGINATION
} from "../../Constants";

const Request = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(Context.UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const [showCreateRequest, setShowCreateRequest] = useState(false);
  const [submitRequest, setSubmitRequest] = useState();
  const [editRequest, setEditRequest] = useState(false);
  const [editRequestDataId, setEditRequestDataId] = useState();
  const [editRequestData, setEditRequestData] = useState();
  const [postEditRequestData, setPostEditRequestData] = useState();
  const [pagination, setPagination] = useState(RESET_PAGINATION);

  const resetPagination = ()=>{
    setPagination(RESET_PAGINATION)
   }

  const navigate = useNavigate();
  const sampleData = { ...BASIC_FORM_DATA, consumer: user.entity_id,consumer_no:"" };
  const formRenderFeilds = {
    consumer_no: { type: "TEXT" },
    purchase_start_date: { type: "DATE" },
    purchase_end_date: { type: "DATE" },
    term_type: { type: "SELECT", dropdownValues: TENURE_OPTIONS },
    technology: { type: "SELECT", dropdownValues: TECHNOLOGY_OPTIONS },
    purchase_type: { type: "SELECT", dropdownValues: PURCHASE_TYPE_OPTIONS },
    power_requirement_kwh: { type: "TEXT" },
    min_rate_rs_per_kwh: { type: "TEXT" },
    remark: { type: "TEXT" },
    status: { type: "HIDE" },
    consumer: { type: "HIDE" },
  };

  const renderFields = (key, rowData) => {
    const value = rowData[key];
    switch (key) {
      case "edit":
        return rowData.status === DRAFT ? renderEditButton(rowData) : EDIT;
      case "view_details":
        return renderDetailsButton(rowData);

      default:
        return value;
    }
  };

  const renderDetailsButton = (rowData) => {
    return (
      <button
        className="font-semibold text-primary py-3 hover:text-green-700"
        onClick={() => {
          navigate(ROUTES.CONSUMER_REQUEST_DETAILS.replace(":id", rowData.id));
        }}
      >
        View
      </button>
    );
  };
  const renderEditButton = (rowData) => {
    return (
      <button
        className="font-semibold text-primary py-3 hover:text-green-700"
        onClick={() => {
          setEditRequest(true);
          setEditRequestData(formatEditRequestData(rowData, sampleData));
          setIsOpen(true);
        }}
      >
        Edit
      </button>
    );
  };

  const formatEditRequestData = (data, sampleData) => {
    setEditRequestDataId(data.id);
    const EditedRequestData = {};
    for (const col of Object.keys(sampleData)) {
      EditedRequestData[col] = data[col];
    }
    return EditedRequestData;
  };
  const getPaginationData = () => {
    if (pagination.offset > pagination.count) return;
    ConsumerService.getRequest(user.entity_id, pagination)
      .then((response) => {
        if (response.status === 200) {
          setRequests((prevData) => {
            let updatedData = [];
            updatedData = new Set([
              ...prevData.map((data) => JSON.stringify(data)),
              ...response.data.results.map((data) => JSON.stringify(data)),
            ]);
            return Array.from(updatedData).map((data) => JSON.parse(data));
          });
        }
        setPagination({
          ...pagination,
          count: response.data.count,
          offset: pagination.offset + pagination.limit,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (submitRequest) {
      setLoading(true);
      ConsumerService.postRequest(user.entity_id, submitRequest)
        .then((response) => {
          if (response.status === 201) {
            toast.success(MESSAGES.REQUEST_SUCCESS);
          } else {
            toast.error(MESSAGES.REQUEST_ERROR);
          }
        })
        .finally(() => {
          setLoading(false);
          setSubmitRequest(null);
          setShowCreateRequest(false);
        });
    }

    if (editRequestDataId && postEditRequestData) {
      setLoading(true);
      ConsumerService.patchRequest(
        user.entity_id,
        postEditRequestData,
        editRequestDataId
      )
        .then((response) => {
          if (response.status === 200) {
            toast.success(MESSAGES.REQUEST_SUCCESS);
          } else {
            toast.error(MESSAGES.REQUEST_ERROR);
          }
        })
        .finally(() => {
          setLoading(false);
          setEditRequest(null);
          setPostEditRequestData(null);
          setEditRequestDataId(null);
        });
    }
    resetPagination();
    getPaginationData();
  }, [user, submitRequest, editRequestDataId, postEditRequestData]);

  return (
    <div className="">
      {isOpen &&
        (showCreateRequest ? (
          <Common.GenericForm
            formData={sampleData}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            handleData={(data) => setSubmitRequest(data)}
            formFieldLabels={RequestColumns}
            renderField={formRenderFeilds}
          />
        ) : editRequest && editRequestData ? (
          <Common.GenericForm
            formData={editRequestData}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            handleData={(data) => setPostEditRequestData(data)}
            formFieldLabels={RequestColumns}
            renderField={formRenderFeilds}
          />
        ) : (
          toast.error(MESSAGES.LOADING_DATA_ERROR)
        ))}

      <div className="flex justify-between mb-4">
        <div className="text-xl text-primary font-semibold mb-4">
          {REQUEST_LIST}
        </div>
        <div>
          <Common.Button
            className="bg-primary hover:bg-secondary text-white py-2 px-7 rounded-md shadow-md"
            onClick={() => {
              setShowCreateRequest(true);
              setIsOpen(true);
            }}
          >
            {Constants.BUTTON_TITLE.CREATE_REQUEST}
          </Common.Button>
        </div>
      </div>
      {loading ? (
        <Component.Spinner />
      ) : (
        <div>
          <InfiniteScroll
            dataLength={requests.length}
            next={getPaginationData}
            hasMore={pagination.offset <= pagination.count}
            scrollableTarget="scrollableDiv"
          >
            <div
              className="h-table_desk overflow-x-auto overflow-y-auto border rounded-md"
              id="scrollableDiv"
            >
              <Component.CommonTable
                tableColumns={["consumer_no", ...BASIC_COLUMN_LABELS]}
                tableRows={requests}
                columnLabels={RequestColumns}
                className={""}
                renderField={renderFields}
                sortingColumn="purchase_start_date"
              />
            </div>
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

export default Request;
