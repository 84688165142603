import React from "react";
import logo from "../../Assets/rem_logo_trans.png";

const Home = () => {
  return (
    <div className="flex flex-col w-full gap-4 text-center h-screen_m md:h-full justify-center items-center">
      <img
        src={logo}
        alt="REM-logo"
        className="w-64 mr-4 hover:cursor-pointer"
      />
      <span className="text-xl md:text-2xl text-secondary">
        Online Platform for renewable power purchase. Add your requests to get
        started.
      </span>
    </div>
  );
};

export default Home;
