// RequestDetails.js
import React, { useEffect, useState, useContext } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import * as Context from "../../Context";
import * as ConsumerService from "../../Services/ConsumerService";
import * as Component from "../../Components";
import * as QuoteListColumns from "../../Data/Quote.json";
import * as RequestListColumns from "../../Data/Request.json";
import {
  MESSAGES,
  REQUEST_DETAILS,
  QUOTE_DETAILS,
  QUOTE_LIST_COLUMNS,
} from "../../Constants";

const RequestDetails = () => {
  const [loading, setLoading] = useState(false);
  const [isQuoteList, setIsQuoteList] = useState(false);
  const [quoteList, setQuoteList] = useState(false);
  const { user } = useContext(Context.UserContext);
  const [requestDetails, setRequestDetails] = useState(null);
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    ConsumerService.getConsumerRequestDetails(user.entity_id, params.id)
      .then((response) => {
        if (response.status === 200) {
          const details = response.data;
          setRequestDetails(details);

          ConsumerService.getQuotesList(user.entity_id, params.id)
            .then((response) => {
              if (response.status === 200) {
                const quoteList = response.data.results;
                setIsQuoteList(true);
                setQuoteList(quoteList);
              } else {
                toast.error(MESSAGES.REQUEST_ERROR);
              }
            })
            .finally(() => setLoading(false));
        } else {
          toast.error(MESSAGES.REQUEST_ERROR);
        }
      })
      .finally(() => setLoading(false));
  }, [user, requestDetails?.id, params.id]);
  if (loading) {
    return <div className="text-center mt-4">{MESSAGES.LOADING}</div>;
  }

  if (!requestDetails) {
    return (
      <div className="text-center mt-4">{MESSAGES.NO_REQUEST_DETAILS}</div>
    );
  }

  return (
    <>
      <div className="md:flex">
        <div className="md:w-1/3 container py-4">
          <div className="flex justify-center mb-4">
            <h1 className="text-2xl font-bold">{REQUEST_DETAILS}</h1>
          </div>

          <Component.Common.ViewForm
            details={requestDetails}
            formFieldLabels={RequestListColumns}
            excludeColumns={"consumer"}
            conditionalClasses={{
              wrapperDiv:
                "bg-white shadow rounded-lg h-[62vh] overflow-y-auto border",
              container: "flex justify-between text-sm p-4 border",
              key: "text-gray-900 font-semibold w-1/2",
              value: "w-1/2 flex justify-end text-gray-800",
            }}
          />
        </div>
        <div className="md:w-2/3 m-4">
          <div className="flex justify-center">
            <h1 className="text-2xl font-bold mb-4">{QUOTE_DETAILS}</h1>
          </div>
          {isQuoteList ? (
            <div
              className="h-[62vh] overflow-x-auto overflow-y-auto border rounded-md"
              id="scrollableDiv"
            >
              <Component.CommonTable
                tableColumns={[...QUOTE_LIST_COLUMNS]}
                tableRows={quoteList}
                columnLabels={QuoteListColumns}
              />
            </div>
          ) : (
            <div> {MESSAGES.NO_QUOTE_ERROR}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default RequestDetails;
