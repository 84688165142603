import React from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "./Components";
import { BrowserTracing } from "@sentry/tracing";
import { UserContextProvider } from "./Context";
import { Toaster } from "react-hot-toast";
import { Spinner } from "./Components";
import * as Sentry from "@sentry/react";
import * as routes from "./routes";
import * as Pages from "./Pages";

try {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
} catch (error) {
  console.log("Ignore: Sentry init issue!");
}

function App() {
  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          loading: {
            icon: <Spinner size={4} />,
          },
        }}
      />
      <UserContextProvider>
        <Routes>
          <Route path={routes.HOME} element={<Layout />}>
            <Route path={routes.HOME} element={<Pages.Home />} />
            <Route path={routes.LOGIN} element={<Pages.Login />} />
            <Route path={routes.SIGNUP} element={<Pages.SignUp />} />

            {/* Consumer Routes */}
            <Route path={routes.CONSUMER}>
              <Route
                path={routes.CONSUMER_PROFILE}
                element={<Pages.Consumer.Profile />}
              />
              <Route
                path={routes.CONSUMER_REQUEST}
                element={<Pages.Consumer.Request />}
              />
              <Route
                path={routes.CONSUMER_REQUEST_DETAILS}
                element={<Pages.Consumer.RequestDetails />}
              />
            </Route>

            {/* Agent Routes */}
            <Route path={routes.AGENT}>
              <Route
                path={routes.AGENT_PROFILE}
                element={<Pages.Agent.Profile />}
              />
              <Route
                path={routes.AGENT_REQUEST}
                element={<Pages.Agent.Request />}
              />
              <Route
                path={routes.AGENT_REQUEST_DETAILS}
                element={<Pages.Agent.RequestDetails />}
              />
            </Route>

            {/* Generator Routes */}
            <Route path={routes.GENERATOR}>
              <Route
                path={routes.GENERATOR_PROFILE}
                element={<Pages.Generator.Profile />}
              />
              <Route
                path={routes.GENERATOR_REQUEST}
                element={<Pages.Generator.Request />}
              />
              <Route
                path={routes.GENERATOR_REQUEST_DETAILS}
                element={<Pages.Generator.RequestDetails />}
              />
            </Route>
            <Route path="*" element={<Pages.NotFound />} />
          </Route>
        </Routes>
      </UserContextProvider>
    </>
  );
}

export default App;
