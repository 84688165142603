import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import * as AgentService from "../../Services/AgentService";
import * as context from "../../Context";
import * as RequestListColumns from "../../Data/Request.json";
import * as Component from "../../Components";
import * as QuoteListColumns from "../../Data/Quote.json";
import {
  MESSAGES,
  REQUEST_DETAILS,
  QUOTE_LIST_COLUMNS,
  QUOTE_DETAILS,
} from "../../Constants";

function RequestDetails() {
  const { user } = useContext(context.UserContext);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [requestDetails, setRequestDetails] = useState(null);
  const [quoteList, setQuoteList] = useState(null);
  const [isQuoteList, setIsQuoteList] = useState(false);

  useEffect(() => {
    setLoading(true);
    AgentService.getAgentRequestDetails(user.entity_id, params.id)
      .then((response) => {
        if (response.status === 200) {
          const details = response.data;
          setRequestDetails(details);

          AgentService.getQuoteList(user.entity_id, params.id)
            .then((response) => {
              if (response.status === 200) {
                const quoteList = response.data.results;
                setIsQuoteList(true);
                setQuoteList(quoteList);
              } else {
                toast.error(MESSAGES.REQUEST_ERROR);
              }
            })
            .finally(() => setLoading(false));
        } else {
          toast.error(MESSAGES.REQUEST_ERROR);
        }
      })
      .finally(() => setLoading(false));
  }, [user.entity_id, params.id]);
  if (loading) {
    return <div className="text-center mt-4">{MESSAGES.LOADING}</div>;
  }

  if (!requestDetails) {
    return (
      <div className="text-center mt-4">{MESSAGES.NO_REQUEST_DETAILS}</div>
    );
  }

  return (
    <div className="md:flex">
      <div className="md:w-1/3 container py-4">
        <div className="flex justify-center text-2xl font-bold mb-4">
          {REQUEST_DETAILS}
        </div>
        <Component.Common.ViewForm
          details={requestDetails}
          formFieldLabels={RequestListColumns}
          excludeColumns={["agent", "consumer_no"]}
          conditionalClasses={{
            wrapperDiv:
              "bg-white shadow rounded-lg h-[62vh] overflow-y-auto border",
            container: "flex justify-between text-sm p-4 border",
            key: "text-gray-900 font-semibold w-1/2",
            value: "w-1/2 flex justify-end text-gray-800",
          }}
        />
      </div>
      <div className="md:w-2/3 m-4">
        <div className="flex justify-center text-2xl font-bold mb-4">
          {QUOTE_DETAILS}
        </div>
        {isQuoteList ? (
          <div
            className="h-[62vh] overflow-x-auto overflow-y-auto border rounded-md"
            id="scrollableDiv"
          >
            <Component.CommonTable
              tableColumns={[...QUOTE_LIST_COLUMNS]}
              columnLabels={QuoteListColumns}
              tableRows={quoteList}
            />
          </div>
        ) : (
          <div> {MESSAGES.NO_QUOTE_ERROR}</div>
        )}
      </div>
    </div>
  );
}

export default RequestDetails;
