import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import * as Component from "../Components";
import * as UserService from "../Services/UserService";
import { LOGIN } from "../routes";
import toast from "react-hot-toast";

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    role: Yup.string().required('Required'),
    orgName: Yup.string().min(8, 'Must be at least 8 characters').required('Required'),
    orgAddress: Yup.string().min(8, 'Must be at least 8 characters').required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().min(5, 'Password must be at least 6 characters').required('Required'),
    contact: Yup.string().matches(/^\d{10}$/, 'Must be exactly 10 digits').required('Required'),
});

const SignUpForm = () => {
    const navigate = useNavigate();
    const initialValues = {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        role: '',
        orgName: '',
        orgAddress: '',
        contact: '',
    };

    const onSubmit = (values, { setSubmitting }) => {
        UserService.signup(values).then(response => {
            if (response.status === 201) {
                toast.success("Sign up successful. Please Login!");
                navigate(LOGIN);
            } else {
                toast.error("Sign up Failed. Contact Admin!");
            }
        }).finally(() => setSubmitting(false));
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => (
                <Form className="space-y-4 md:space-y-1 w-full">
                    <div className="flex gap-1">
                        <div>
                            <label htmlFor="first_name" className="block text-sm font-medium leading-6 text-gray-900">
                                First Name
                            </label>
                            <div className="mt-2">
                                <Field
                                    type="text"
                                    name="first_name"
                                    id="first_name"
                                    placeholder="Vivek"
                                    required
                                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                                />
                                <ErrorMessage name="first_name" component="div" className="text-red-500 text-xs mt-1" />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="last_name" className="block text-sm font-medium leading-6 text-gray-900">
                                Last Name
                            </label>
                            <div className="mt-2">
                                <Field
                                    type="text"
                                    name="last_name"
                                    id="last_name"
                                    placeholder="Patil"
                                    required
                                    className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                                />
                                <ErrorMessage name="last_name" component="div" className="text-red-500 text-xs mt-1" />
                            </div>
                        </div>
                    </div>

                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Email address
                        </label>
                        <div className="mt-2">
                            <Field
                                type="email"
                                name="email"
                                id="email"
                                autoComplete="email"
                                placeholder="user@example.com"
                                required
                                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                            />
                            <ErrorMessage name="email" component="div" className="text-red-500 text-xs mt-1" />
                        </div>
                    </div>
                    <div className="mt-2">
                        <label>
                            <Field type="radio" name="role" value="GENERATOR" className="mx-2" />
                            Generator
                        </label>
                        <label>
                            <Field type="radio" name="role" value="CONSUMER" className="mx-2" />
                            Consumer
                        </label>
                        <label>
                            <Field type="radio" name="role" value="AGENT" className="mx-2" />
                            Agent
                        </label>
                        <ErrorMessage name="role" component="div" className="text-red-500 text-xs mt-1" />
                    </div>
                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                Password
                            </label>
                        </div>
                        <div className="mt-2">
                            <Field
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                placeholder="************"
                                required
                                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                            />
                            <ErrorMessage name="password" component="div" className="text-red-500 text-xs mt-1" />

                        </div>
                    </div>
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                            Organization Name
                        </label>
                        <div className="mt-2">
                            <Field
                                type="text"
                                name="orgName"
                                id="orgName"
                                placeholder="Solar Generator Private Limited"
                                required
                                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                            />
                            <ErrorMessage name="orgName" component="div" className="text-red-500 text-xs mt-1" />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="orgAddress" className="block text-sm font-medium leading-6 text-gray-900">
                            Organization Address
                        </label>
                        <div className="mt-2">
                            <Field
                                type="text"
                                name="orgAddress"
                                id="orgAddress"
                                placeholder="Pune"
                                required
                                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                            />
                            <ErrorMessage name="orgAddress" component="div" className="text-red-500 text-xs mt-1" />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="contact" className="block text-sm font-medium leading-6 text-gray-900">
                            Organization Contact
                        </label>
                        <div className="mt-2">
                            <Field
                                type="text"
                                name="contact"
                                id="contact"
                                placeholder="9876543210"
                                required
                                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                            />
                            <ErrorMessage name="contact" component="div" className="text-red-500 text-xs mt-1" />
                        </div>
                    </div>
                    <div>
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
                        >
                            {!isSubmitting ? "Sign up" : <Component.Spinner />}
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default SignUpForm;