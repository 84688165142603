import React from "react";
import { Link } from "react-router-dom";
import { SIGNUP } from "../routes";
import * as Components from "../Components";

const Login = () => {
    return (
        <div className="flex flex-col md:flex-row gap-8 text-center md:justify-between md:h-screen_desk content-center">
            <Components.ComingSoon />
            <hr className="md:hidden shadow-md"></hr>

            <div className="flex justify-center items-center h-full w-full md:w-1/2">
                <div className=" px-6 py-4 space-y-4 w-full md:max-w-md bg-white border rounded-lg shadow-md text-left">
                    <div className="flex flex-col gap-4 items-center rounded-lg">
                        <h1 className="text-xl font-bold text-center leading-tight tracking-tight text-secondary md:text-2xl">
                            Log in to your account
                        </h1>
                        <div className="w-full">
                            <Components.LoginForm />
                        </div>
                    </div>
                    <p className="text-center text-sm text-secondary">
                        Not a member?{' '}
                        <Link to={SIGNUP} className="font-semibold leading-6 text-primary hover:text-secondary">
                            Create a Account!
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;

