import React, { useContext } from 'react'
import { Link, useLocation } from "react-router-dom";
import * as routes from "../routes";
import * as Context from "../Context";

const AppMenu = ({ className }) => {
    const { user } = useContext(Context.UserContext);
    const current_url = useLocation()

    if (!user) return;
    const current_path = current_url.pathname;
    const menu_options = routes.MENU_OPTIONS[user.role]

    return (
        <>
            {Object.entries(menu_options).map(([menuName, path]) => {
                const style = path.trim() === current_path.trim()
                    ? "text-gray-700 font-semibold border-b-2 border-secondary "
                    : "text-gray-700";
                return (
                    <Link to={path} className={`${style} ${className}`} key={path}>
                        <p className='text-md'>{menuName}</p>
                    </Link>
                )
            })}
        </>
    )
}

export default AppMenu