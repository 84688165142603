import React, { useContext } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import * as Component from "../Components";
import * as UserService from "../Services/UserService";
import * as Context from "../Context";

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().min(5, 'Password must be at least 6 characters').required('Required'),
});

const LoginForm = () => {
    const { login } = useContext(Context.UserContext);

    const initialValues = {
        email: '',
        password: '',
    };

    const onSubmit = (values, { setSubmitting }) => {
        UserService.login(values).then(response => {
            if (response.status === 200) {
                login(response.data)
            }
        }).finally(() => setSubmitting(false));
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => (
                <Form className="space-y-4 w-full">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-secondary">
                            Email address
                        </label>
                        <div className="mt-2">
                            <Field
                                type="email"
                                name="email"
                                id="email"
                                autoComplete="email"
                                placeholder="user@example.com"
                                required
                                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                            />
                            <ErrorMessage name="email" component="div" className="text-red-500 text-xs mt-1" />
                        </div>
                    </div>

                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-secondary">
                                Password
                            </label>
                        </div>
                        <div className="mt-2">
                            <Field
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                placeholder="************"
                                required
                                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                            />
                            <ErrorMessage name="password" component="div" className="text-red-500 text-xs mt-1" />

                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
                        >
                            {!isSubmitting ? "Log in" : <Component.Spinner />}
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default LoginForm;