import client from "./axiosConfig";

export const getConsumer = (consumerId) => {
  const config = {
    url: `/consumer/${consumerId}`,
    method: "GET"
  };
  const response = client(config);
  return response;
};

export const updateConsumerProfile = (id, data) => {
  const config = {
    url: `/consumer/${id}/`,
    method: "PATCH",
    data
  };
  const response = client(config);
  return response;
};

export const getRequest = (consumerId,params) => {
  const config = {
    url: `/consumer/${consumerId}/requests/`,
    method: "GET",
    params
  };
  const response = client(config);
  return response;
};

export const getConsumerRequestDetails = (consumerId, requestId) => {
  const config = {
    url: `/consumer/${consumerId}/requests/${requestId}/`,
    method: "GET"
  };
  const response = client(config);
  return response;
};

export const getQuotesList = (consumerId, requestId) => {
  const config = {
    url: `/consumer/${consumerId}/requests/${requestId}/quote/`,
    method: "GET"
  };
  const response = client(config);
  return response;
};


export const postRequest = (consumerId, data) => {
  const config = {
    url: `/consumer/${consumerId}/requests/`,
    method: "POST",
    data
  };
  const response = client(config);
  return response;
};

export const patchRequest = (consumerId, data, requestId) => {
  const config = {
    url: `/consumer/${consumerId}/requests/${requestId}/`,
    method: "PATCH",
    data
  };
  const response = client(config);
  return response;
};