import React from "react";
import * as Constants from "../Constants";

const Footer = () => {
  return (
    <footer className="p-4 bg-white rounded-lg shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800 md:h-footer_desk">
      <span className="text-xs md:text-sm text-secondary sm:text-center dark:text-gray-400">
        © 2024{" "}
        {Constants.BRAND_NAME}
        . All Rights Reserved.
      </span>
      <ul className="flex flex-wrap items-center mt-3 text-xs md:text-sm text-secondary dark:text-gray-400 sm:mt-0">
        <li>
          <a href="/" className="mr-4 hover:underline md:mr-6 ">
            About
          </a>
        </li>
        <li>
          <a href="/" className="mr-4 hover:underline md:mr-6">
            Privacy Policy
          </a>
        </li>
        <li>
          <a href="/" className="mr-4 hover:underline md:mr-6">
            Licensing
          </a>
        </li>
        <li>
          <a href="/" className="hover:underline">
            Contact
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
