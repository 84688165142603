import React from "react";

const DropdownBox = ({
  handleSelectChange,
  filterOptions,
  value = null,
  defaultOption = "Select",
  disabled = false,
}) => {
  return (
    <div>
      <select
        value={value}
        disabled={disabled}
        onChange={handleSelectChange}
        className="bg-primary hover:bg-secondary text-white py-2 px-7 rounded-md shadow-md flex justify-center  items-center w-full font-medium text-sm"
      >
        <option value="">{defaultOption}</option>
        {filterOptions?.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
};
export default DropdownBox;
