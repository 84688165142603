import React, { useState } from "react";

// formData => json of initial form data Key-DefaultValue pair
// renderField => json of key - value which declares input type(SELECT, DATE, TEXT) and input type meta info if needed e.g.  { "key": { "type": "SELECT", "dropdownValues": ["1", "2", "3"] } }
// isOpen => boolean - to open the form
// onClose => function/ method to close the form
// handleData => get calls on submit the form

const GenericForm = ({
  formData,
  renderField,
  isOpen,
  onClose,
  handleData,
  formFieldLabels = null,
}) => {
  const [updatedFormData, setUpdatedFormData] = useState(formData);
  const handleChange = (fieldName, value) => {
    setUpdatedFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleData(updatedFormData);
    onClose();
  };

  return (
    <div className="overflow-y-scroll">
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-lg max-w-3xl w-full relative min-h-min max-h-[80vh] overflow-y-scroll sm:max-w-sm md:max-w-lg lg:max-w-xl">
            <div>
              <h2 className="text-xl text-primary flex justify-center font-bold mb-4">
                Power Purchase Request
              </h2>
            </div>
            <button
              onClick={onClose}
              className="absolute top-2 right-0 m-4 text-xl cursor-pointer focus:outline-none"
            >
              ❌
            </button>
            <form onSubmit={(e) => handleSubmit(e)}>
              {Object.entries(updatedFormData).map(
                ([fieldName, fieldValue]) => (
                  <div
                    key={fieldName}
                    className="grid gap-6 mb-6 md:grid-cols-2"
                  >
                    {renderField[fieldName].type === "HIDE" ? (
                      <></>
                    ) : (
                      <label
                        htmlFor={fieldName}
                        className="block mb-2 text-sm font-medium text-gray-900"
                      >
                        {formFieldLabels && formFieldLabels[fieldName]
                          ? formFieldLabels[fieldName]
                          : fieldName}
                      </label>
                    )}
                    {(() => {
                      switch (renderField[fieldName].type) {
                        case "SELECT":
                          return (
                            <div>
                              <select
                                id={fieldName}
                                name={fieldName}
                                value={updatedFormData[fieldName]}
                                onChange={(e) =>
                                  handleChange(fieldName, e.target.value)
                                }
                                required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              >
                                <option value="" disabled>
                                  Select
                                </option>
                                {renderField[fieldName].dropdownValues?.map(
                                  (option, index) => (
                                    <option key={index} value={option}>
                                      {formFieldLabels &&
                                      formFieldLabels[option]
                                        ? formFieldLabels[option]
                                        : option}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          );
                        case "DATE":
                          return (
                            <div>
                              <input
                                type="date"
                                value={updatedFormData[fieldName]}
                                onChange={(e) =>
                                  handleChange(fieldName, e.target.value)
                                }
                                className="p-2 w-full text-md sm:text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                              />
                            </div>
                          );
                        case "TEXT":
                          return (
                            <div>
                              <input
                                type="text"
                                id={fieldName}
                                name={fieldName}
                                value={updatedFormData[fieldName]}
                                onChange={(e) =>
                                  handleChange(fieldName, e.target.value)
                                }
                                required
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              />
                            </div>
                          );
                        case "HIDE":
                          return null;
                        default:
                          return (
                            <p className="text-red-500 text-sm">
                              Form field input type is not defined
                            </p>
                          );
                      }
                    })()}
                  </div>
                )
              )}
              <div className="flex justify-center">
                <button
                  type="submit"
                  className="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:ring focus:border-secondary"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default GenericForm;
