export const BRAND_NAME = "Renewable Energy Market";

export const ROLES = {
  GENERATOR: "GENERATOR",
  CONSUMER: "CONSUMER",
  AGENT: "AGENT",
};

export const BUTTON_TITLE = {
  CREATE_REQUEST: "Create Request",
  CREATE_QUOTE: "Submit Quote",
};
export const DRAFT = "Draft";
export const EDIT = "Edit";
export const INPROGRESS = "INPROGRESS";
export const ASC = "asc";
export const DESC = "desc";
export const REQUEST_LIST = "Request list";
export const REQUEST_DETAILS = "Request Details";
export const QUOTE_DETAILS = "Quote Details";
export const SUBMITTED = "SUBMITTED";
export const STATUS_OPTIONS = ["INPROGRESS", "COMPLETED", "CANCELLED"];
export const TENURE_OPTIONS = ["SHORT", "MEDIUM", "LONG"];
export const TECHNOLOGY_OPTIONS = ["SOLAR", "WIND", "OTHER"];
export const PURCHASE_TYPE_OPTIONS = ["IPP", "CPP", "Any"];

export const MESSAGES = {
  REQUEST_SUCCESS: "Request Submitted Succesfully",
  REQUEST_ERROR: "Failed to load requests",
  LOADING_DATA_ERROR: "error while Loading Data",
  NO_REQUEST_DETAILS: "No request details available",
  LOADING: "Loading...",
  NO_QUOTE_ERROR: "No Quote Submitted yet",
  QUOTE_SUBMITTED: "Quote Submitted Succesfully",
  LOADING_QUOTE_FAILED: "Failed to load quotation",
};
export const BASIC_FORM_DATA = {
  purchase_start_date: "",
  purchase_end_date: "",
  term_type: "",
  technology: "",
  purchase_type: "",
  power_requirement_kwh: "",
  min_rate_rs_per_kwh: "",
  remark: "",
  status: "INPROGRESS",
};
export const BASIC_COLUMN_LABELS = [
  "purchase_start_date",
  "purchase_end_date",
  "power_requirement_kwh",
  "min_rate_rs_per_kwh",
  "purchase_type",
  "status",
  "edit",
  "view_details",
];
export const QUOTE_LIST_COLUMNS = [
  "generator_tech",
  "injection_point",
  "injection_voltage",
  "quoted_capacity_kwh",
  "quoted_rate_rs_per_kwh",
  "power_requirement_kwh",
  "remark",
];

export const VOLTAGE_OPTIONS = [
  "KV_11",
  "KV_22",
  "KV_33",
  "KV_132",
  "KV_220",
  "KV_440",
];

export const RESET_PAGINATION = {
  count: 0,
  offset: 0,
  limit: 10,
};
