import Cookies from 'js-cookie';

export const getObjectFromArray = (array, key, value) => array.find(obj => obj[key] === value);

// Set a cookie with the provided key, value, and expiration time
export const setCookie = (key, value, expiresInDays, isJson = false) => {
    if (isJson) {
        value = JSON.stringify(value)
    }

    Cookies.set(key, value, { expires: expiresInDays });
};

// Get the value of the cookie with the provided key
export const getCookie = (key, isJson) => {
    let value = Cookies.get(key);
    if (isJson && value) {
        return JSON.parse(value)
    }
    return value
};

// Remove the cookie with the provided key
export const removeCookie = (key) => {
    Cookies.remove(key);
};

export const getUser = () => {
    return getCookie('user', true);
}

export const removeUser = () => {
    removeCookie('user');
}

export const setUser = (user) => {
    setCookie('user', user, 30, true);
}

export const getInitials = (fullName) => {
    if (!fullName) return '';
    const namesArray = fullName.split(' ');
    const initials = namesArray.map(name => name.charAt(0).toUpperCase());
    return initials.join('');
};

export const getLocalDateTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString('en-IN', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });
}
export const getLocalDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString('en-IN', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });
}
