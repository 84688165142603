// RequestDetails.js
import React, { useEffect, useState, useContext } from "react";
import * as Context from "../../Context";
import * as GeneratorService from "../../Services/GeneratorService";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import * as Constants from "../../Constants";
import * as Common from "../../Components/Common";
import * as QuoteColumns from "../../Data/Quote.json";
import * as RequestColumns from "../../Data/Request.json";
import {
  SUBMITTED,
  VOLTAGE_OPTIONS,
  MESSAGES,
  REQUEST_DETAILS,
  TECHNOLOGY_OPTIONS,
} from "../../Constants";
import QuoteDetails from "./QuoteDetails";

const RequestDetails = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useContext(Context.UserContext);
  const [requestDetails, setRequestDetails] = useState(null);
  const [quoteData, setQuoteData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isQuote, setIsQuote] = useState(false);
  const [showCreateQuote, setShowCreateQuote] = useState(false);
  const [submitQuote, setSubmitQuote] = useState(false);
  const params = useParams();
  const excludeColumns = ["id", "created", "updated", "generator", "request"];
  const QuoteData = {
    quoted_capacity_kwh: "",
    injection_point: "",
    injection_voltage: "",
    generator_tech: "",
    quoted_rate_rs_per_kwh: "",
    remark: "",
    status: SUBMITTED,
    generator: user.entity_id,
    request: requestDetails?.id,
  };

  const QuoteRenderFeilds = {
    quoted_capacity_kwh: { type: "TEXT" },
    injection_point: { type: "TEXT" },
    injection_voltage: {
      type: "SELECT",
      dropdownValues: VOLTAGE_OPTIONS,
    },
    generator_tech: {
      type: "SELECT",
      dropdownValues: TECHNOLOGY_OPTIONS,
    },
    quoted_rate_rs_per_kwh: { type: "TEXT" },
    remark: { type: "TEXT" },
    status: { type: "HIDE" },
    generator: { type: "HIDE" },
    request: { type: "HIDE" },
  };

  useEffect(() => {
    setLoading(true);
    GeneratorService.getGeneratorRequestDetails(user.entity_id, params.id)
      .then((response) => {
        if (response.status === 200) {
          const details = response.data.request;
          setRequestDetails(details);

          GeneratorService.getQuoteDetails(user.entity_id, details.id)
            .then((response) => {
              if (response.status === 200 && response.data.results.length > 0) {
                const quotedetails = response.data.results;
                setIsQuote(true);
                setQuoteData(quotedetails);
              }
            })
            .finally(() => setLoading(false));
        } else {
          toast.error(MESSAGES.REQUEST_ERROR);
        }
      })
      .finally(() => setLoading(false));

    if (submitQuote) {
      setLoading(true);
      GeneratorService.postQuote(user.entity_id, requestDetails.id, submitQuote)
        .then((response) => {
          if (response.status === 201) {
            toast.success(MESSAGES.QUOTE_SUBMITTED);
          } else {
            toast.error(MESSAGES.LOADING_QUOTE_FAILED);
          }
        })
        .finally(() => {
          setLoading(false);
          setSubmitQuote(null);
          setShowCreateQuote(false);
        });
    }
  }, [user, requestDetails?.id, submitQuote, params.id]);

  if (loading) {
    return <div className="text-center mt-4">{MESSAGES.LOADING}</div>;
  }

  if (!requestDetails) {
    return (
      <div className="text-center mt-4">{MESSAGES.NO_REQUEST_DETAILS}</div>
    );
  }

  return (
    <>
      <div className="container mx-auto p-4">
        <div className="flex justify-between md:mb-4">
          <h1 className="text-2xl font-bold mb-4 text-primary">
            {REQUEST_DETAILS}
          </h1>
          {!isQuote && (
            <button
              className="bg-primary hover:bg-secondary text-white py-2 px-3 rounded-md shadow-md"
              onClick={() => {
                setShowCreateQuote(true);
                setIsOpen(true);
              }}
            >
              {Constants.BUTTON_TITLE.CREATE_QUOTE}
            </button>
          )}
        </div>

        <Common.ViewForm
          details={requestDetails}
          formFieldLabels={RequestColumns}
          excludeColumns={["id"]}
        />
      </div>
      <div className="flex flex-col gap-4">
        <div>
          {isOpen &&
            (showCreateQuote ? (
              <Common.GenericForm
                formData={QuoteData}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                handleData={(data) => setSubmitQuote(data)}
                formFieldLabels={QuoteColumns}
                renderField={QuoteRenderFeilds}
              />
            ) : (
              toast.error(MESSAGES.LOADING_DATA_ERROR)
            ))}
        </div>
        <div>
          {isQuote ? (
            quoteData.map((data, index) => (
              <QuoteDetails
                quoteData={data}
                formFieldLabels={QuoteColumns}
                excludeColumns={[excludeColumns]}
              />
            ))
          ) : (
            <div className="px-4"> {MESSAGES.NO_QUOTE_ERROR}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default RequestDetails;
