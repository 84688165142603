import React, { useContext } from "react";
import { ROLES } from "../Constants";
import * as Pages from "../Pages";
import * as Context from "../Context";


const Home = () => {
  const { user } = useContext(Context.UserContext);
  const role = user.role;

  switch (role) {
    case ROLES.CONSUMER:
      return (
        <Pages.Consumer.Home />
      )

    case ROLES.AGENT:
      return (
        <Pages.Agent.Home />
      )
    case ROLES.GENERATOR:
      return (
        <Pages.Generator.Home />
      )

    default:
      break;
  }
};

export default Home;
