import React, { useState } from "react";
import { useEffect } from "react";
import * as Utils from "../Utils/index";

export const UserContext = React.createContext();

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState("");

  const login = (user) => {
    Utils.setUser(user);
    setUser(user);
  };
  const logout = () => {
    Utils.removeCookie("user");
    setUser("");
  };

  const values = {
    user,
    setUser,
    login,
    logout,
  };

  useEffect(() => {
    const user = Utils.getUser();
    setUser(user);
  }, []);

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
};
export default UserContextProvider;
