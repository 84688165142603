import React, { useContext, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import * as Component from "../../Components";
import * as Context from "../../Context";
import * as AgentService from "../../Services/AgentService";
import toast from "react-hot-toast";

// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    contact: Yup.string().matches(/^\d{10}$/, 'Must be exactly 10 digits').required('Required'),
    address: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
});

const Profile = () => {
    const { user } = useContext(Context.UserContext);
    const [loading, setLoading] = useState(false);
    const [updateProfile, setUpdateProfile] = useState(false);
    const [initialValues, setInitialValues] = useState({
        fullName: user.full_name,
        email: user.email,
    });


    const onSubmit = (values, { setSubmitting }) => {
        setLoading(true);
        AgentService.updateAgentProfile(user.entity_id, values).then(response => {
            if (response.status === 200) {
                toast.success("Agent profile updated successfully!");
            }
        }).finally(() => { setSubmitting(false); setLoading(false); setUpdateProfile(true) });
    };

    useEffect(() => {
        setLoading(true);
        AgentService.getAgent(user.entity_id).then((response) => {
            if (response.status === 200) {
                setInitialValues((prev => ({ ...prev, ...response.data })));
            } else {
                toast.error("Failed to generator details");
            }
        }).finally(() => { setLoading(false); setUpdateProfile(false) });
    }, [user, updateProfile]);

    return (
        <div>
            {
                loading
                    ? <Component.Spinner size={4} />
                    : <div className="border rounded-lg shadow-md">
                        <h1 className="text-xl my-4 font-bold text-center leading-tight tracking-tight text-secondary md:text-2xl">
                            Profile
                        </h1>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                            enableReinitialize
                        >
                            {({ isSubmitting }) => (
                                <Form className="space-y-4 w-full p-4">
                                    <div>
                                        <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">
                                            Full Name
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                type="text"
                                                name="fullName"
                                                id="fullName"
                                                disabled
                                                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                                            />
                                            <ErrorMessage name="fullName" component="div" className="text-red-500 text-xs mt-1" />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                            Email address
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                type="email"
                                                name="email"
                                                id="email"
                                                disabled
                                                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                                            />
                                            <ErrorMessage name="email" component="div" className="text-red-500 text-xs mt-1" />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                                            Organization Name
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                type="text"
                                                name="name"
                                                id="name"
                                                required
                                                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                                            />
                                            <ErrorMessage name="name" component="div" className="text-red-500 text-xs mt-1" />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="contact" className="block text-sm font-medium leading-6 text-gray-900">
                                            Organization Mobile
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                type="number"
                                                name="contact"
                                                id="contact"
                                                required
                                                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                                            />
                                            <ErrorMessage name="contact" component="div" className="text-red-500 text-xs mt-1" />
                                        </div>
                                    </div>


                                    <div>
                                        <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900">
                                            Organization Address
                                        </label>
                                        <div className="mt-2">
                                            <Field
                                                type="text"
                                                name="address"
                                                id="address"
                                                required
                                                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                                            />
                                            <ErrorMessage name="address" component="div" className="text-red-500 text-xs mt-1" />
                                        </div>
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary"
                                        >
                                            {!isSubmitting ? "Submit" : <Component.Spinner />}
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
            }
        </div>
    )
}

export default Profile;