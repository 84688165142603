import React, { useContext } from "react";
import { AppHeader, Header, Footer, InternetStatus } from "../../Components";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { TbClockShield } from "react-icons/tb";
import { LOGIN, SIGNUP, HOME } from "../../routes";
import * as Context from "../../Context";

const PUBLIC_ROUTES = [LOGIN, SIGNUP];

const Layout = () => {
  const { user } = useContext(Context.UserContext);
  const location = useLocation();
  const isLoggedIn = Boolean(user);

  // Redirect user to Home page is trying to open login, signup page when logged in
  if (isLoggedIn && PUBLIC_ROUTES.indexOf(location.pathname) !== -1) {
    return <Navigate to={HOME} />;
  }

  // Redirect user to is user is trying to access protected route
  if (!isLoggedIn && PUBLIC_ROUTES.indexOf(location.pathname) === -1) {
    return <Navigate to={LOGIN} />;
  }

  // Check if user is approved if trying to access protected route
  const is_account_approved = (user) => {
    if (PUBLIC_ROUTES.indexOf(location.pathname) !== -1) {
      return true;
    }
    return user?.is_approved;
  };

  const PendingApproval = () => {
    return (
      <div className="flex justify-center align-middle h-screen_m md:h-full px-4">
        <div className="flex flex-col gap-4 justify-center items-center">
          <TbClockShield size={100} className=" text-amber-400" />
          <div className="text-center text-gray-600">
            <p className="text-xl font-semibold">
              Your account is under review!{" "}
            </p>
            <p>You can use account once fully approved.</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div>
        <div>{user ? <AppHeader /> : <Header />}</div>
        <main className="min-h-screen_m md:h-screen_desk bg-white overflow-y-auto">
          <div className="container mx-auto py-2 px-2 md:px-0 md:py-4 h-full">
            <InternetStatus>
              {is_account_approved(user) ? <Outlet /> : <PendingApproval />}
            </InternetStatus>
          </div>
        </main>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Layout;
