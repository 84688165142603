import React from "react";

// details => json data in form of key value pair
// formFieldLabes=> Labels to be shown on UI (key value JSON)
// excludeColumns => Columns to excluded on UI (array of strings)
// conditionalClasses => custom classes for replacing deafault classes (kay value json)
// conditionalClasses.wrapperDiv: Custom classes for the entire ViewForm container. Defaults to a styled, responsive grid layout.
// conditionalClasses.container: Custom classes for each key-value pair. Defaults to a flexbox with a separating bottom border.
// conditionalClasses.key and conditinalClasses.value Custom classes for the key text and value.

const ViewForm = ({
  details = {},
  formFieldLabels,
  excludeColumns = [],
  conditionalClasses = {},
  ...rest
}) => {
  const classes =
    conditionalClasses.wrapperDiv ||
    "bg-white shadow rounded-lg p-4 grid md:grid-cols-2 gap-6";
  const containerClass =
    conditionalClasses.container ||
    "flex justify-between border-b border-gray-200 py-2";
  const keyClass = conditionalClasses.key || "font-medium text-gray-600";
  const valueClass = conditionalClasses.value || "text-gray-800";

  return (
    <div className={classes} {...rest}>
      {Object.entries(details).map(
        ([key, value]) =>
          !excludeColumns.includes(key) && (
            <div key={key} className={containerClass}>
              <div className={keyClass}>
                {formFieldLabels && formFieldLabels[key]
                  ? formFieldLabels[key]
                  : key}
              </div>
              <div className={valueClass}>{value}</div>
            </div>
          )
      )}
    </div>
  );
};

export default ViewForm;
