import React from 'react'

const QuoteDetails = ({ quoteData, formFieldLabels, excludeColumns }) => {
  return (
    <>
      <div className='flex flex-col gap-2 px-4'>
        <div>
          <h1 className="text-2xl font-bold mb-4 text-primary">Quote Details</h1>
        </div>
        <div className="bg-white shadow rounded-lg px-2 grid gap-6 md:grid-cols-2">
          {Object.entries(quoteData).map(([key, value]) => (
            !excludeColumns.includes(key) && (<div key={key} className="flex flex-col sm:flex-row justify-between border-b border-gray-200 py-2">
              <div className="font-medium text-gray-600">
                {formFieldLabels && formFieldLabels[key] ? formFieldLabels[key] : key}
              </div>
              <div className="text-gray-800">{value}</div>
            </div>)
          ))}
        </div>
      </div>
    </>
  )
}

export default QuoteDetails